import React from "react";
import './common.css';
import smallLogoG from '../assets/small-g.png';
import grabbitLogo from '../assets/grabbit-logo.png';
const CancellationAndRefundPolicy = () => {
  return (
    <div>
           <header>
<img
       src={smallLogoG}
       alt="Grabbit Small Logo"
       className="small-logo"
     />
<h1>Grabbit</h1>
</header>
<main>
      <h1>Cancellation and Refund Policy</h1>
      <h2>Returns and Exchanges</h2>
      <p>
        Due to the perishable nature of groceries, we do not accept returns or
        exchanges unless the product you received is damaged.
      </p>
      <h2>Damaged Products</h2>
      <p>
        If you receive a damaged product, please contact us within 24 hours of
        delivery at support@grabbit.com or by phone at +917233019175. We will
        do our best to resolve the issue as quickly as possible.
      </p>
      <h2>Refund Processing Time</h2>
      <p>
        If a refund is approved, it will be processed within 7 business days.
        The refund will be credited back to the original payment method.
      </p>
      </main>
    </div>
  );
};

export default CancellationAndRefundPolicy;
