// LandingPage.tsx
import React from 'react';
import './LandingPage.css';
import smallLogoG from '../assets/small-g.png';
import grabbitLogo from '../assets/grabbit-logo.png';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
  return (
    <div className="container">
      <header>
        <img src={smallLogoG} alt="Grabbit Small Logo" className="small-logo" />
        <h1>Grabbit</h1>
      </header>
      <main>
        <section className="hero">
          <img src={grabbitLogo} alt="Grabbit Logo" className="grabbit-logo" />
          <h2>Quick Grocery Delivery App</h2>
          <p>
            Now in <strong>Dhanbad</strong>. Get ready for huge discounts and
            unbeatable prices on your favorite grocery products.
          </p>
          <div className="cta">
            <a href="https://play.google.com/store/apps/details?id=in.gograbbit" className="play-store">
              Get it on Google Play
            </a>
            <a href="https://apps.apple.com/in/app/grabbit-grocery-shopping/id6450187826" className="app-store">
              Get it on App Store
            </a>
          </div>
        </section>
      </main>
      <footer>
<p>© 2023 Grabbit. All rights reserved.</p>
<nav>
<Link to="/privacy-policy">Privacy Policy</Link>
<span> | </span>
<Link to="/terms-and-conditions">Terms & Conditions</Link>
<span> | </span>
<Link to="/cancellation-and-refund-policy">Cancellation & Refund Policy</Link>
<span> | </span>
<Link to="/shipping-and-delivery-policy">Shipping & Delivery Policy</Link>
<span> | </span>
<Link to="/contact-us">Contact Us</Link>
</nav>
</footer>

    </div>
  );
};

export default LandingPage;
