import React from "react";
import './common.css';
import smallLogoG from '../assets/small-g.png';
import grabbitLogo from '../assets/grabbit-logo.png';
const PrivacyPolicy = () => {
  return (
    <div>
      <header>
<img
       src={smallLogoG}
       alt="Grabbit Small Logo"
       className="small-logo"
     />
<h1>Grabbit</h1>
</header>
<main>
<h1>Privacy Policy</h1>

      <p>
        At Grabbit, we are committed to protecting your privacy. This Privacy
        Policy explains what personal information we collect from our website and
        app users, how we collect it, how we use it, and how we keep it safe.
      </p>
      <h2>What personal information we collect</h2>
      <p>
        We collect your location and mobile number when you log in to our app.
        This information is required for the proper functioning of the app and
        for us to be able to deliver groceries to you.
      </p>
      <h2>How we collect the information</h2>
      <p>
        We collect your location and mobile number when you create an account
        with us or log in to the app. The information is securely stored on our
        servers.
      </p>
      <h2>How we use the collected information</h2>
      <p>
        We use the information collected from you to deliver groceries to you.
        Your location is used to determine the delivery location, and your
        mobile number is used to send you updates on your delivery status and
        to contact you in case of any issues.
      </p>
      <h2>How we keep the information safe</h2>
      <p>
        We take the security of your information very seriously. We have
        implemented appropriate technical and organizational measures to protect
        your personal information from unauthorized access, use, or disclosure.
        Only authorized personnel have access to your information, and it is
        only used for the purposes set out in this Privacy Policy.
      </p>
      <h2>Information sharing with third parties</h2>
      <p>
        We do not share your personal information with third parties, except as
        required by law or to protect our rights. If we need to share your
        information with third parties for any other reason, we will only do so
        with your explicit consent.
      </p>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
