import React from "react";
import './common.css';
import smallLogoG from '../assets/small-g.png';
import grabbitLogo from '../assets/grabbit-logo.png';
const TermsAndConditions = () => {
  return (
    <div>
         <header>
<img
       src={smallLogoG}
       alt="Grabbit Small Logo"
       className="small-logo"
     />
<h1>Grabbit</h1>
</header>
<main>
      <h1>Terms and Conditions</h1>
      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns regarding these Terms and
        Conditions, please contact us at support@grabbit.com.
      </p>
      <h2>Effective Date</h2>
      <p>
        These Terms and Conditions are effective as of 1st May, 2023. We reserve
        the right to change these terms at any time, and any changes will be
        posted on this page. Your continued use of the Grabbit app indicates
        your acceptance of any changes to these terms.
      </p>
      <h2>Limitation of Liability and Disclaimer of Warranties</h2>
      <p>
        The Grabbit app is provided on an "as is" and "as available" basis.
        We make no representations or warranties of any kind, express or
        implied, as to the operation of the app or the information, content,
        materials, or products included on the app.
      </p>
      <p>
        To the fullest extent permissible by law, we will not be liable for any
        damages of any kind arising from the use of the Grabbit app, including
        but not limited to direct, indirect, incidental, punitive, and
        consequential damages.
      </p>
      <h2>Rules of Conduct</h2>
      <p>
        By using the Grabbit app, you agree to use the app only for lawful
        purposes and in a manner that does not infringe the rights of, or
        restrict or inhibit the use and enjoyment of the app by, any third
        party. Prohibited conduct includes, but is not limited to, using the
        app to transmit or post any material that is abusive, defamatory,
        obscene, or otherwise violates any law.
      </p>
      <h2>User Restrictions</h2>
      <p>
        You may not use the Grabbit app for any unauthorized or illegal
        purpose. You are responsible for ensuring that your use of the app
        complies with all applicable laws and regulations. We reserve the right
        to terminate your use of the app if we believe that you have violated
        these Terms and Conditions or engaged in any illegal activity.
      </p>
      </main>
    </div>
  );
};

export default TermsAndConditions;
