import React from "react";
import './LandingPage.css';
import smallLogoG from '../assets/small-g.png';

const ContactUs: React.FC = () => {
return (
<div className="container">
<header>
<img src={smallLogoG} alt="Grabbit Small Logo" className="small-logo" />
<h1>Grabbit</h1>
</header>
<main>
<h1>Contact Us</h1>
<h2>Email</h2>
<p>
If you have any questions or concerns, you can reach us by email at
support@grabbit.com.
</p>
<h2>Mobile Number</h2>
<p>
You can also reach us by phone at +917233019175.
</p>
<h2>Operating Address</h2>
<p>
Our day-to-day business is conducted from:
<br />
Kola Kusma Lipidih, Near TV Center Boundary,
<br />
Dhanbad, Jharkhand,
<br />
PO 828109
</p>
</main>
<footer>
<p>© 2023 Grabbit. All rights reserved.</p>
</footer>
</div>
);
};

export default ContactUs;