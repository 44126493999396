import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import TermsAndConditions from './pages/TermsAndConditions.jsx';
import CancellationAndRefundPolicy from './pages/CancellationAndRefundPolicy.jsx';
import ShippingAndDeliveryPolicy from './pages/ShippingAndDeliveryPolicy.jsx';
import ContactUs from './pages/ContactUs.jsx';

const App: React.FC = () => {
  return (
    <>
    
    <Routes>
   <Route exact path="/" element={<LandingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/cancellation-and-refund-policy" element={<CancellationAndRefundPolicy />} />
      <Route path="/shipping-and-delivery-policy" element={<ShippingAndDeliveryPolicy />} />
      <Route path="/contact-us" element={<ContactUs />} />
           </Routes>
           </>
  );
};

export default App;
