import React from "react";
import './common.css';
import smallLogoG from '../assets/small-g.png';
import grabbitLogo from '../assets/grabbit-logo.png';
const ShippingAndDeliveryPolicy = () => {
  return (
    <div>
         <header>
<img
       src={smallLogoG}
       alt="Grabbit Small Logo"
       className="small-logo"
     />
<h1>Grabbit</h1>
</header>
<main>
      <h1>Shipping and Delivery Policy</h1>
      <h2>Order Processing and Shipping Time</h2>
      <p>
        We aim to deliver your order within 60 minutes of receiving it. During
        busy periods, delivery times may be longer.
      </p>
      <h2>Shipping Costs</h2>
      <p>
        Currently, we do not charge any shipping fees for orders placed on the
        Grabbit app. However, we reserve the right to charge a small fee in the
        future, which will be communicated to you in advance.
      </p>
    </main>
    </div>
  );
};

export default ShippingAndDeliveryPolicy;
